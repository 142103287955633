import styled, { css } from "styled-components";
import { renderToStaticMarkup as rtsm } from 'react-dom/server';
import { FaChevronRight } from 'react-icons/fa';

const rightIcon = css`
  ${rtsm(<FaChevronRight size={20} color="white" />).replace(/"/g, "'")}
`;
export default function Button({ primary, type, children, onClick, className }) {
  return (
    <StyledButton type={type ? type : "button"} primary={primary} onClick={onClick} className={className}>
      {children}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  background: ${(props) => (props.primary ? "linear-gradient(275.76deg, #2ECBD5 44.33%, #1E9EBA 98.56%)" : 
  "linear-gradient(275.76deg, #CC2C6A 44.33%, #981246 98.56%)")};
  color: ${(props) => (props.primary ? "#ffffff" : "#F2F4F3")};
  padding: 0 2rem 0 3rem;
  width: 378px;
  border-radius: 40px;
  font-size: 48px;
  line-height: 80px;
  font-weight: 300;
  cursor: pointer;
  border: none;
  box-shadow: ${(props) => (props.primary ? "0px 10px 10px 0px #2ED57326"  : "0px 10px 10px 0px #98124640")};
  margin: 10px 20px;
  box-sizing: border-box;
  transition: width 500ms ease 0ms , letter-spacing 500ms ease 0ms;
  &::after {
    margin-left: 0px;
    position: relative;
    top: -5px;
    content: url("data:image/svg+xml; utf8,${rightIcon}");
    opacity: 0;
    transition: margin 500ms ease 0ms;
  }

  &:hover {
    transition-property: all !important;
    transition-pduration: 200mx !important;
    letter-spacing: 2px;
    width: 390px;
    &::after {
      margin-left: 10%;
      opacity: 1;
    }
  }
  @media (max-width: 767px) {
    width: 230px;
    font-size: 28px;
    line-height: 40px;
    padding: 10px 1rem 10px 2rem;
    &:hover {
      width: 230px;
      letter-spacing: 1.2px;
      &::after {
        top: 1px;
        opacity: 1;
        margin-left: 8%;
      }
    }
  }

`;
