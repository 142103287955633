import styled from "styled-components";
import bgImg from "../assets/bg.jpg";
import Button from "../components/Button";
export default function Vcard() {
  return (
    <StyledHome>
      <Button type="submit" primary onClick={() => null}>
        Download VCard
      </Button>
    </StyledHome>
  );
}

const StyledHome = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(${bgImg});
  background-size: cover;
  background-repeat: no-repeat;
  h2 {
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.2rem;
  }
`;
