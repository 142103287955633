import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Roboto', sans-serif;
    }

    body {
        background: #ffffff;
        color: #f2f4f3;
        /* color: #e4e4e4; */
    }

    h1, h2, h3, h4, h5, h6, b {
        font-weight: 600;
    }

    a{
        color: inherit;
        text-decoration: none;
    }

`;

export default GlobalStyles;
