import styled from "styled-components";
import { useEffect } from "react";
import bgImg from "../assets/bg.jpg";
export default function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <StyledNotFound>
      <h2>404</h2>
      <p>Page not found</p>
    </StyledNotFound>
  );
}

const StyledNotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(${bgImg});
  background-size: cover;
  background-repeat: no-repeat;
  h2 {
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.2rem;
  }
`;
