import styled from "styled-components";
import bgImg from "../assets/bg.jpg";
import "@google/model-viewer";

export default function ARCamera() {
  const onDownloadHandler = () => {
    console.log("Downloading...")
  }
  return (
    <StyledHome>
      <model-viewer
        id="warpper"
        src="./models/Male1Test.glb"
        ar
        ar-scale="fixed"
        camera-controls
        alt="A 3D model of an astronaut"
        ios-src="./models/nxtsys-business-card.usdz"
        xr-environment
      >
        <button id="button-load" onClick={onDownloadHandler}>Download</button>
      </model-viewer>
      {/* <model-viewer
        ar
        ar-modes="webxr scene-viewer quick-look"
        camera-controls
        src="./../assets/models/Male1Test.glb"
        alt="A 3D model of an astronaut"
      >
        <button slot="ar-button" id="button-load">
          👋 Activate AR
        </button>
      </model-viewer> */}
    </StyledHome>
  );
}

const StyledHome = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(${bgImg});
  background-size: cover;
  background-repeat: no-repeat;
  h2 {
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.2rem;
  }
  #warpper {
    padding-top: 5vh;
    width: 30%;
    height: 50vh
  }
  #lazy-load-poster {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  #button-load {
    background: linear-gradient(275.76deg, #2ECBD5 44.33%, #1E9EBA 98.56%);
    color: #ffffff;
    border-radius: 20px;
    display: inline-block;
    padding: 10px 40px 9px 40px;
    font-weight: 500;
    position: absolute;
    left: 35%;
    bottom: 0%;
    z-index: 100;
    cursor: pointer;
    border: none;
    box-shadow: 0px 10px 10px 0px #2ED57326;
    @media (max-width: 767px) {
      left: 0%;
    }
  }
`;
