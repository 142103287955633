import { Routes, Route, Navigate } from "react-router";

//Global Style
import GlobalStyles from "./components/GlobalStyles";
import "./App.css";
//Pages
import Home from "./pages/Home";
import HomeFlyer from "./pages/HomeFlyer";
import HomeJared from "./pages/HomeJared";
import HomeJustin from "./pages/HomeJustin";
import ARCamera from "./pages/ARCamera";
import Vcard from "./pages/Vcard";
import NotFound from "./pages/NotFound";
import { HelmetProvider } from "react-helmet-async";
//   token : n1uzt9nbgvTteiFSGa543aFG
import SnackbarProvider from "react-simple-snackbar";
export default function App() {
  return (
    <HelmetProvider>
      <SnackbarProvider>
        <div className="App">
          <GlobalStyles />
          <Routes>
            <Route path="/flyer" element={<HomeFlyer />} />
            <Route path="/dave" element={<Home />} />
            <Route path="/jared" element={<HomeJared />} />
            <Route path="/justin" element={<HomeJustin />} />
            <Route path="/ar" element={<ARCamera />} />
            <Route path="/vcard" element={<Vcard />} />
            <Route exact path="/" element={<Navigate to="/flyer" />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </SnackbarProvider>
    </HelmetProvider>
  );
}
